<template>
  <main class="background flow-root">
    <svg
      class="absolute bottom-10 left-10 hidden h-auto w-[164px] lg:block lg:w-auto"
      width="285"
      height="30"
      viewBox="0 0 285 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_642_744)">
        <path
          d="M40.0768 12.9081C29.9429 9.50877 18.0573 8.27954 16.3367 8.53995C16.2405 8.55395 16.2377 8.64075 16.3056 8.66595C18.4026 9.43037 34.6038 11.9728 34.6038 18.777C34.6038 23.9096 28.5648 26.7124 24.3086 26.7124C20.3071 26.7124 16.2745 24.8336 13.9653 20.4767C13.8974 20.3479 13.7785 20.2527 13.6398 20.2163C12.8814 20.0147 12.1456 19.8018 11.4297 19.5806C11.1071 19.4882 10.7901 19.3846 10.4817 19.2838H10.476C10.3939 19.2614 10.3119 19.2362 10.2326 19.211C10.1166 19.1746 9.95813 19.337 9.99492 19.4546C14.237 32.7522 31.2815 33.0434 37.2328 22.7335C38.5685 20.3087 38.7128 17.6626 38.0818 16.1533C38.0025 15.9629 38.178 15.7641 38.3761 15.8313C39.5052 16.2094 40.2863 16.467 40.2863 16.467C42.0068 17.0382 43.8491 17.7018 44.4943 18.7798C45.6461 20.6979 42.6888 21.9299 39.9636 22.3107C39.5646 22.3667 39.5703 22.5235 39.8957 22.5291C50.7767 22.7755 50.4966 16.397 40.0825 12.9053M31.6381 21.4567C31.7343 21.4427 31.7371 21.3559 31.6692 21.3307C29.5722 20.5663 13.371 18.021 13.371 11.2196C13.371 6.08708 19.4129 3.28421 23.6662 3.28421C27.6677 3.28421 31.7003 5.16306 34.0095 9.51997C34.0774 9.64598 34.1963 9.74398 34.335 9.78038C35.0934 9.98198 35.8291 10.1948 36.5451 10.416C36.8677 10.5084 37.1847 10.612 37.4931 10.7128H37.4988C37.5809 10.7352 37.6629 10.7604 37.7422 10.7856C37.8582 10.822 38.0167 10.6596 37.9799 10.542C33.7378 -2.75556 16.6933 -3.04676 10.742 7.26311C9.4063 9.68798 9.25914 12.334 9.89304 13.8433C9.97228 14.0337 9.79683 14.2325 9.59873 14.1653C8.4696 13.7873 7.68854 13.5297 7.68854 13.5297C5.96796 12.9585 4.12569 12.2948 3.48047 11.2168C2.3287 9.29877 5.28595 8.06673 8.01115 7.68592C8.413 7.62992 8.40734 7.47312 8.07907 7.46752C-2.80192 7.22111 -2.52176 13.5997 7.8923 17.0914C18.0262 20.4907 29.9118 21.7199 31.6324 21.4595"
          fill="white"
        />
        <g ref="logoRef">
          <path
            d="M126.695 14.9156C125.999 13.5492 124.901 11.3231 124.21 9.98187C122.334 6.08136 117.232 4.57772 113.496 6.80938C112.115 7.6018 111.023 8.92344 110.361 10.3347C110.146 10.7575 109.582 11.8579 109.359 12.3003C107.423 16.1168 105.428 19.9921 103.521 23.8338C103.43 24.0158 103.566 24.2286 103.77 24.2286H107.794C107.899 24.2286 107.995 24.1698 108.043 24.0774L111.736 16.7608C112.489 15.3048 113.607 12.9919 114.373 11.5779C115.647 9.13625 119.077 9.12224 120.362 11.5527C121.089 12.9499 122.201 15.1648 123.395 17.5505C123.486 17.7325 123.353 17.9481 123.146 17.9481H115.423C115.321 17.9481 115.231 18.0013 115.18 18.0881L113.584 20.9134C113.482 21.0954 113.615 21.3222 113.824 21.3222H125.099C125.204 21.3222 125.303 21.381 125.348 21.4762C125.798 22.3806 126.236 23.2598 126.644 24.0774C126.692 24.1698 126.785 24.2286 126.893 24.2286H130.906C131.11 24.2286 131.245 24.0158 131.155 23.8338C129.686 20.8462 128.186 17.8809 126.689 14.9156"
            fill="white"
          />
          <path
            d="M231.14 14.9156C230.444 13.5492 229.346 11.3231 228.656 9.98187C226.779 6.08136 221.677 4.57772 217.942 6.80938C216.561 7.6018 215.468 8.92344 214.806 10.3347C214.591 10.7575 214.028 11.8579 213.804 12.3003C211.869 16.1168 209.873 19.9921 207.966 23.8338C207.876 24.0158 208.011 24.2286 208.215 24.2286H212.239C212.344 24.2286 212.44 24.1698 212.488 24.0774L216.184 16.7608C216.937 15.3048 218.055 12.9919 218.822 11.5779C220.095 9.13625 223.525 9.12224 224.81 11.5527C225.537 12.9499 226.649 15.1648 227.843 17.5505C227.934 17.7325 227.801 17.9481 227.594 17.9481H219.869C219.77 17.9481 219.676 18.0013 219.628 18.0881L218.032 20.9134C217.93 21.0954 218.063 21.3222 218.273 21.3222H229.547C229.652 21.3222 229.751 21.381 229.796 21.4762C230.246 22.3806 230.685 23.2598 231.092 24.0774C231.14 24.1698 231.234 24.2286 231.338 24.2286H235.354C235.558 24.2286 235.694 24.0158 235.603 23.8338C234.134 20.8462 232.637 17.8809 231.137 14.9156"
            fill="white"
          />
          <path
            d="M77.8399 22.2074C75.4854 24.3354 71.7216 24.3382 68.971 24.3382H68.9426C63.6337 24.3382 59.9605 23.1034 59.1314 22.7954C59.0238 22.7562 58.9531 22.6554 58.9531 22.5406V19.3597C58.9531 19.1721 59.1342 19.0405 59.3125 19.0965C60.5095 19.4801 64.5365 20.6589 68.7106 20.6869C69.8284 20.6953 70.9547 20.6813 72.0697 20.5749C73.0121 20.4853 75.242 20.0597 74.7723 18.6513C74.5968 18.1249 73.7478 17.9176 73.2498 17.8168C73.1536 17.7972 63.263 15.6608 63.2574 15.6608C61.1717 15.1148 59.2134 14.44 58.605 12.2951C58.1324 10.6179 58.7267 8.84261 59.9888 7.77578C62.7423 5.43771 69.1238 5.66732 69.1238 5.66732C73.3856 5.66732 76.7136 6.47374 77.5484 6.69495C77.6701 6.72575 77.7522 6.83495 77.7522 6.96096V9.95144C77.7522 10.1222 77.5965 10.2538 77.4267 10.2202C76.2495 10.0046 72.1263 9.29062 69.0757 9.29062C68.2833 9.29062 62.9319 9.01061 62.9772 10.9007C62.9885 11.3375 63.6366 11.8751 65.0883 12.2055C65.1874 12.2279 73.9063 14.0787 74.7043 14.2831C77.5427 15.0028 78.7341 16.1172 79.1813 17.6684C79.6369 19.2505 79.0907 21.0761 77.8427 22.2074"
            fill="white"
          />
          <path
            d="M97.259 5.8778H83.3613C83.2085 5.8778 83.084 6.001 83.084 6.15221V23.8515C83.084 24.0027 83.2085 24.1259 83.3613 24.1259H86.7742C86.927 24.1259 87.0515 24.0027 87.0515 23.8515V9.5179C87.0515 9.36669 87.176 9.24349 87.3288 9.24349H97.259C98.657 9.24349 99.8427 10.2487 99.8427 11.4443V12.122C99.8427 13.312 98.6598 14.3956 97.259 14.3956H90.5663C90.4134 14.3956 90.2889 14.5188 90.2889 14.67V17.4841C90.2889 17.6353 90.4134 17.7585 90.5663 17.7585H97.4882C101.088 17.7585 103.906 15.2805 103.906 11.9932V11.7356C103.906 8.50147 100.924 5.875 97.259 5.875"
            fill="white"
          />
          <path
            d="M138.155 11.4444C137.27 12.5112 137.083 14.0793 137.077 14.9977C137.08 15.9133 137.27 17.5682 138.155 18.6322C139.834 20.6566 143.646 20.9422 145.403 20.9534C149.277 20.9786 152.894 20.203 153.992 19.9426C154.167 19.9006 154.331 20.0322 154.331 20.2086V23.1067C154.331 23.2299 154.252 23.3363 154.133 23.3699C152.693 23.7843 147.655 24.3891 145.326 24.3219C142.737 24.2463 140.507 23.9747 138.509 23.2635C137.957 23.0647 133.113 21.1691 132.982 15.1685V14.8409C133.115 8.82072 137.957 6.92227 138.509 6.72347C140.417 6.04305 142.528 5.66504 144.97 5.66504C146.818 5.66504 149.269 5.66504 154.102 6.60026C154.232 6.62546 154.328 6.74027 154.328 6.87187V9.79515C154.328 9.96875 154.17 10.1004 153.997 10.064C152.803 9.82035 148.604 9.02513 144.953 9.02513C143.875 9.02513 140.023 9.18753 138.153 11.4416"
            fill="white"
          />
          <path
            d="M177.42 16.4251V13.611C177.42 13.4598 177.296 13.3366 177.143 13.3366H167.063C166.91 13.3366 166.786 13.4598 166.786 13.611V16.4251C166.786 16.5763 166.91 16.6995 167.063 16.6995H177.143C177.296 16.6995 177.42 16.5763 177.42 16.4251ZM177.799 5.88281H159.844C159.691 5.88281 159.566 6.00602 159.566 6.15722V23.8397C159.566 23.9909 159.691 24.1141 159.844 24.1141H177.799C177.952 24.1141 178.077 23.9909 178.077 23.8397V21.0256C178.077 20.8744 177.952 20.7512 177.799 20.7512H163.811C163.658 20.7512 163.534 20.628 163.534 20.4768V9.52011C163.534 9.36891 163.658 9.2457 163.811 9.2457H177.799C177.952 9.2457 178.077 9.1225 178.077 8.9713V6.15722C178.077 6.00602 177.952 5.88281 177.799 5.88281Z"
            fill="white"
          />
          <path
            d="M205.587 24.1231H201.235C201.162 24.1231 201.088 24.0951 201.037 24.0419L193 15.9553C192.946 15.9021 192.876 15.8741 192.802 15.8741H188.492C188.339 15.8741 188.215 15.9973 188.215 16.1485V23.8487C188.215 23.9999 188.09 24.1231 187.937 24.1231H184.527C184.375 24.1231 184.25 23.9999 184.25 23.8487V6.14941C184.25 5.9982 184.375 5.875 184.527 5.875H187.937C188.09 5.875 188.215 5.9982 188.215 6.14941V12.234C188.215 12.3852 188.339 12.5084 188.492 12.5084H193.026C193.096 12.5084 193.167 12.4804 193.218 12.4328L200.032 5.9506C200.083 5.903 200.154 5.875 200.225 5.875H204.628C204.877 5.875 204.999 6.17461 204.821 6.34541L196.792 14.0736C196.682 14.18 196.679 14.3508 196.786 14.46L205.783 23.6555C205.953 23.8291 205.828 24.1203 205.585 24.1203"
            fill="white"
          />
          <path
            d="M256.838 17.7725C256.722 17.6269 256.784 17.4169 256.959 17.3469C259.314 16.4173 260.927 14.3088 260.927 11.9932V11.7356C260.927 8.50147 257.944 5.875 254.274 5.875H240.379C240.226 5.875 240.102 5.9982 240.102 6.14941V23.8487C240.102 23.9999 240.226 24.1231 240.379 24.1231H243.792C243.945 24.1231 244.069 23.9999 244.069 23.8487V9.5151C244.069 9.36389 244.194 9.24069 244.346 9.24069H254.274C255.677 9.24069 256.86 10.2459 256.86 11.4415V12.1276C256.86 13.3232 255.677 14.334 254.274 14.334H247.584C247.431 14.334 247.307 14.4572 247.307 14.6084V17.4225C247.307 17.5737 247.431 17.6969 247.584 17.6969H251.885C251.97 17.6969 252.052 17.7361 252.106 17.8033L256.954 24.0139C257.007 24.0811 257.087 24.1203 257.174 24.1203H261.343C261.575 24.1203 261.705 23.8543 261.561 23.6751L256.84 17.7697L256.838 17.7725Z"
            fill="white"
          />
          <path
            d="M285.001 6.15722V8.9713C285.001 9.1225 284.876 9.2457 284.723 9.2457H276.913C276.76 9.2457 276.635 9.36891 276.635 9.52011V23.8425C276.635 23.9937 276.511 24.1169 276.358 24.1169H272.945C272.792 24.1169 272.668 23.9937 272.668 23.8425V9.52011C272.668 9.36891 272.543 9.2457 272.391 9.2457H264.58C264.427 9.2457 264.303 9.1225 264.303 8.9713V6.15722C264.303 6.00602 264.427 5.88281 264.58 5.88281H284.723C284.876 5.88281 285.001 6.00602 285.001 6.15722Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_642_744">
          <rect width="285" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <div class="grid-default container grid h-screen pt-20 lg:pt-40">
      <div class="col-span-full lg:col-span-4 lg:h-screen">
        <BackHome class="mb-20 ml-4" />
        <slot name="sidebar" />
        <div class="max-w-xs">
          <Heading class="mb-4 text-cyan">INQUIRY</Heading>
          <p class="text-alto">Please let us know some information about your project.</p>
        </div>
      </div>
      <div class="col-span-full lg:col-span-8 lg:h-screen lg:pt-7">
        <slot />
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import locales from '~/i18n/locales'

const { locale } = useI18n()
const langAttr = computed(() => locales.find((l) => l.code === locale.value).code)

useHead({
  htmlAttrs: {
    lang: langAttr
  }
})
</script>

<style lang="postcss" scoped>
.background {
  background: url('~/assets/images/backgrounds/detail-top-bg.webp') 0 0 / 1440px 259px no-repeat;
}
</style>
